<nav #navbarBrand id="navbar-brand" class="navbar navbar-expand-lg navbar-dark bg-primary" style="padding: 0;">
    <div class="container">
        <a routerLink="/home" class="navbar-brand" href="/home" style="height: 65px; padding: 10px 0;"
            aria-label="Go back to home page">
            <img src="assets/logo.webp" width="45" height="45" style="max-height: 100%; width: 45px; height: 45px;"
                alt="">
            <img src="assets/1artifactware.webp" width="248" height="20" style="padding-left: 10px; max-height: 45%;"
                alt="">
        </a>
        <div class="d-flex align-items-center">
            <div style="border-color: #03BFCB;text-align: right;
        font-size: 12px;
        line-height: 21px;
        border-right: 1px solid #fff;
          border-right-color: #03BFCB;
        padding-right: 45px;margin: 0 15px;color: #FFF; font-weight: 500;">
                75 N Main St. 640<br>
                Randolph, Massachusetts
            </div>
            <div>
                <div style="color: #FFF; height: 65px;
                line-height: 65px;padding-left: 15px;font-weight: 700;
font-size: 13px;
display: block;cursor: pointer;" id="dropdownMenuButton">
                    <i class="fas fa-flag-usa" style="border-radius: 3px;"></i> EN
                </div>
            </div>
        </div>
    </div>
</nav>
<nav #navbarTop id="navbar-top" class="border-top border-muted navbar navbar-expand-lg navbar-dark bg-primary fixed-top"
    style="min-height: 50px; padding: 0px;">
    <div class="container" style="min-height: 50px;">
        <a routerLink="/home" id="brand" class="navbar-brand" href="/home" style="height: 65px; padding: 15px 0;"
            aria-label="Go back to home page">
            <img src="assets/logo.webp" width="35" height="35" style="max-height: 100%; width: 35px; height: 35px;"
                alt="">
            <img src="assets/1artifactware.webp" width="195" height="15" style="padding-left: 10px; max-height: 45%;"
                alt="">
        </a>
        <button class="navbar-toggler" type="button" (click)="isMenuCollapsed = !isMenuCollapsed" aria-expanded="false"
            aria-label="Toggle navigation">
            <i class="fas fa-bars"></i>
        </button>
        <div class="collapse navbar-collapse" id="navbarSupportedContent" [ngbCollapse]="isMenuCollapsed">
            <div class="d-flex" style="justify-content: space-between; align-items: center; width: 100%;">
                <div>
                    <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                        <li class="nav-item">
                            <a routerLink="/home" routerLinkActive="disabled" (click)="isMenuCollapsed = true"
                                class="nav-link" aria-current="page" href="/home"
                                aria-label="Go back to home page">Home</a>
                        </li>
                        <li class="nav-item">
                            <a routerLink="/services" routerLinkActive="disabled" (click)="isMenuCollapsed = true"
                                class="nav-link" aria-current="page" href="/services"
                                aria-label="Services provided">Services</a>
                        </li>
                        <!-- <li class="nav-item">
                            <a routerLink="/portfolio" routerLinkActive="disabled" class="nav-link" aria-current="page" href="#">Portfolio</a>
                        </li> -->
                        <li class="nav-item">
                            <a routerLink="/blog" routerLinkActive="disabled" (click)="isMenuCollapsed = true"
                                class="nav-link" aria-current="page" href="/blog" aria-label="Blog">Blog</a>
                        </li>
                        <li class="nav-item">
                            <a routerLink="/about" routerLinkActive="disabled" (click)="isMenuCollapsed = true"
                                class="nav-link" aria-current="page" href="/about" aria-label="About us">About Us</a>
                        </li>
                        <li class="nav-item">
                            <a routerLink="/contact" routerLinkActive="disabled" (click)="isMenuCollapsed = true"
                                class="nav-link" aria-current="page" href="/contact" aria-label="Contact us">Contact
                                Us</a>
                        </li>
                    </ul>
                </div>
                <div id="call-us">
                    <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                        <li>
                            <a href="tel:781-473-0499" style="font-weight: 600;
                font-size: 14px;
                padding-right: 0;color: #03BFCB;text-transform: uppercase;text-decoration: none;
text-align: left;height: 50px;
line-height: 50px;position: relative;
display: block;"><span style="font-weight: 700;
padding-right: 5px;
color: #FFF;
font-size: 13px;" aria-label="Call us">Call us: </span>(781) 473-0499</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</nav>