<section class="p-4 text-center text-lg-start bg-primary text-white">
    <div class="container text-center text-md-start mt-5">
        <div class="row mt-3">
            <div class="col-lg-4 col-md-8 col-sm-12 col-xs-12" style="margin-left: 0 !important">
                <p class="text-uppercase fw-bold mb-4 h6">
                    <i class="fas fa-boxes me-3"></i>1ARTIFACTWARE
                </p>
                <p>
                    We are a 100% US based company! <i class="fas fa-flag-usa"></i><br>
                    Check us out on social media<br><br>
                    1artifactware LLC<br>
                    75 N Main St. 640<br>
                    Randolph, Massachusetts<br>
                    (781) 473-0499
                </p>
            </div>
            <div class="col-lg-2 col-md-4 col-sm-12 col-xs-12">
                <p class="text-uppercase fw-bold mb-4 h6">
                    Menu
                </p>
                <p>
                    <a routerLink="/home" href="/home" class="text-reset" aria-label="Go back to home page">Home</a>
                </p>
                <p>
                    <a routerLink="/services" href="/services" class="text-reset"
                        aria-label="Services provided">Services</a>
                </p>
                <p>
                    <a routerLink="/blog" href="/blog" class="text-reset" aria-label="Blog">Blog</a>
                </p>
                <p>
                    <a routerLink="/about" href="/about" class="text-reset" aria-label="See about us">About
                        Us</a>
                </p>
            </div>
            <div class="col-lg-3 col-md-8 col-sm-12 col-xs-12">
                <p class="text-uppercase fw-bold mb-4 h6">
                    Talk to us
                </p>
                <p>
                    <a routerLink="/contact" href="/contact" class="text-reset" aria-label="Contact us">Contact</a>
                </p>
                <p>
                    <a href="mailto:contact@1artifactware.com" class="text-reset" aria-label="Send us an email"><i
                            class="far fa-envelope"></i>contact@1artifactware.com</a>
                </p>
                <p>
                    <a href="tel:781-473-0499" class="text-reset" aria-label="Call us"><i
                            class="fas fa-phone-alt"></i>(781) 473-0499</a>
                </p>
            </div>
            <div class="col-lg-3 col-md-4 col-sm-12 col-xs-12" style="margin-right: 0 !important">
                <p class="text-uppercase fw-bold mb-4 h6">Social</p>
                <p><i class="fab fa-facebook-square"></i><a
                        href="https://www.facebook.com/profile.php?id=100087737721285" class="text-reset"
                        target="_blank" rel="nofollow">Facebook</a>
                </p>
                <p><i class="fab fa-twitter-square"></i><a href="https://twitter.com/1artifactware" class="text-reset"
                        aria-label="Visit our twitter page" target="_blank" rel="nofollow">Twitter</a></p>
                <p><i class="fab fa-linkedin"></i><a href="https://www.linkedin.com/company/1artifactware"
                        class="text-reset" aria-label="Visit our linked in page" target="_blank"
                        rel="nofollow">LinkedIn</a></p>
                <p><i class="fab fa-reddit-square"></i><a href="https://www.reddit.com/user/1artifactware"
                        class="text-reset" aria-label="Visit our reddit profile" target="_blank"
                        rel="nofollow">Reddit</a></p>
                <p><i class="fas fa-star"></i><a href="https://www.trustpilot.com/review/1artifactware.com"
                        class="text-reset" aria-label="Look us up on trust pilot" target="_blank" rel="nofollow">Trust
                        Pilot</a></p>
            </div>
        </div>
        <div class="row d-flex flex-row">
            <div class="col-lg-9 col-md-8 col-sm-12 col-xs-12 clutch">
                <a target="_blank" href="https://www.goodfirms.co/" rel="nofollow"><img style="width:180px"
                        src="assets/badge-5.svg" title="View Our Profile on GoodFirms"
                        alt="View Our Profile on GoodFirms"></a>
                <br>
            </div>
            <div class="col-lg-3 col-md-4 col-sm-12 col-xs-12">
                <a href="https://appdevelopmentcompanies.co/local-agencies/us/software-developers/massachusetts"
                    target="_blank" rel="nofollow">
                    <img src="assets/software-development-companies.webp" alt="app developers massachusetts"
                        aria-label="App Developers Massachusetts" width="100" height="100">
                </a>
            </div>
        </div>
    </div>
</section>
<div class="p-4 text-center bg-primary text-white border-top border-secondary">
    <div style="font-size:95%; margin-top:-5px; margin-bottom:10px;">
        <a routerLink="/terms" href="/terms" class="text-reset" aria-label="See terms and conditions">Terms of Use</a>
        |
        <a routerLink="/policy" href="/policy" class="text-reset" aria-label="Our privacy policy">Privacy Policy</a>
    </div>
    <p>
        Copyright © {{year}} Copyright: All Rights Reserved / 1artifactware LLC
    </p>
</div>