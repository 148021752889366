import { DOCUMENT } from "@angular/common";
import { Inject, Injectable } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { RouterStateSnapshot, TitleStrategy } from "@angular/router";

@Injectable({ providedIn: 'root' })
export class TemplatePageTitleStrategy extends TitleStrategy {
  constructor(
    private readonly title: Title,
    @Inject(DOCUMENT) private doc: any
  ) {
    super();
  }

  override updateTitle(routerState: RouterStateSnapshot) {
    const title = this.buildTitle(routerState);
    if (title !== undefined) {
      this.title.setTitle(`${title} - Custom Business Software Made in the USA - 1artifactware`);
    }
    const head: HTMLHeadElement = this.doc.head;
    const elements: HTMLCollectionOf<Element> = head.getElementsByTagName('link');
    for (let n = elements.length - 1; n > 0; n--) {
      if (elements.item(n)!.hasAttribute('rel') && elements.item(n)!.getAttribute('rel') == 'canonical') {
        elements.item(n)!.setAttribute('href', this.doc.URL);
        return;
      }
    }
    const link: HTMLLinkElement = this.doc.createElement('link');
    link.setAttribute('rel', 'canonical');
    if (title === 'home') {
      const rootUrlArray = (this.doc.URL as string).split('/');
      rootUrlArray.pop();
      link.setAttribute('href', rootUrlArray?.join('/'));
    } else {
      link.setAttribute('href', this.doc.URL);
    }
    this.doc.head.appendChild(link);
  }
}
