import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxGoogleAnalyticsModule, NgxGoogleAnalyticsRouterModule } from 'ngx-google-analytics';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TransferHttpCacheModule } from '@nguniversal/common';
import { ActionReducer, StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { TitleStrategy } from '@angular/router';
import { TemplatePageTitleStrategy } from './strategy/template-page-title-strategy';

import { HeaderComponent } from './component/header/header.component';
import { FooterComponent } from './component/footer/footer.component';
import { PageNotFoundComponent } from './component/page-not-found/page-not-found.component';
import { environment } from '../environments/environment';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { RecaptchaModule } from '@1artifactware/recaptcha';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    PageNotFoundComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    TransferHttpCacheModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    NgxGoogleAnalyticsModule.forRoot('AW-11031570619'),
    NgxGoogleAnalyticsRouterModule,
    NgbModule,
    StoreModule.forRoot({}, {
      metaReducers: !environment.production ? [
        (reducer: ActionReducer<any>) => (state, action) => {
          console.log('state', state);
          console.log('action', action);
          return reducer(state, action);
        }
      ] : []
    }),
    EffectsModule.forRoot([]),
    MatSnackBarModule,
    RecaptchaModule
  ],
  providers: [
    { provide: TitleStrategy, useClass: TemplatePageTitleStrategy }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
