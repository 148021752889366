import { Component, ElementRef, ViewChild } from '@angular/core';
import { ScrollDispatcher, ViewportRuler } from '@angular/cdk/scrolling';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {
  @ViewChild('navbarTop', { static: false })
  navbarTop!: ElementRef<HTMLElement>

  @ViewChild('navbarBrand', { static: false })
  navbarBrand!: ElementRef<HTMLElement>

  isMenuCollapsed = true;

  constructor(
    private readonly scrollDispatcher: ScrollDispatcher,
    private readonly viewportRuler: ViewportRuler) {
    this.scrollDispatcher.scrolled().subscribe(() => {
      let navbarBrandHeight = Number(this.navbarBrand.nativeElement.clientHeight)
      this.navbarTop.nativeElement.style.top = `${navbarBrandHeight - window.scrollY > 0 ? navbarBrandHeight - window.scrollY : 0}px`;
    });
    this.viewportRuler.change().subscribe(() => {
      let navbarBrandHeight = Number(this.navbarBrand.nativeElement.clientHeight)
      this.navbarTop.nativeElement.style.top = `${navbarBrandHeight - window.scrollY > 0 ? navbarBrandHeight - window.scrollY : 0}px`;
    });
  }
}
